import { utils, write, writeFile } from 'xlsx';

export const saveXLSX = (data: Record<string, any>[], name: string = 'sheet'): void => {
  const sheet = utils.json_to_sheet(data);
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, name);

  const excelBuffer = write(book, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${name}`;
  a.click();
  URL.revokeObjectURL(url);
};
