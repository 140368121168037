// @ts-nocheck
import { AppEnvironment, environment as common } from '@env/environment.common';

export const environment: AppEnvironment = {
  production: true,
  name: 'staging',
  default: 'api',
  dws_base_url: 'https://dws-staging.divinea.com',
  features: {
    ...common.features,
    'automations': true,
    'sendgrid-statistics': true,
    'campaign-active': true,
    'company-management-privacy-files': true,
    'company-management-payouts': true,
    'unsubscribe-campaign': true,
    'save-template': true,
    'days-no-purchase-automation': true,
    'conversion-statistic': true,
    'subscribe-link': false,
    'subscription-conditions': true,
    'subscription-invitation-automation': true,
    'order-send-receipt-email': true,
    'product-channel-categories': true,
    'archive-contact': true,
    'winery-channel-name': true,
    'external-id-channel': true,
    'delete-channels': true,
    'winery-transactional-emails': true,
    'google-calendar-integration': true,
    'winelivery': true,
    'automatic-approve': true,
    'wineplatform': true,
    'spotty': true,
    'filters-widget': true,
    'country-in-reservation': true,
    'category-product': true,
    'availability-data-pdf': true,
    'close-matching-slot-languages-in-the-same-experience': true,
    'automatically-accept-reservations-on-same-slot': true
  },
  values: {
    ...common.values,
    'fcm-worker': 'assets/js/firebase-messaging-sw-staging.js',
    firebaseConfig: {
      apiKey: 'AIzaSyDITjW8j9D705OAUqyTnuRK8OrRoHWiBvw',
      authDomain: 'still-summit-235616.firebaseapp.com',
      databaseURL: 'https://still-summit-235616.firebaseio.com',
      projectId: 'still-summit-235616',
      storageBucket: 'still-summit-235616.appspot.com',
      messagingSenderId: '77597896257',
      appId: "1:77597896257:web:4d572e59aa4eeb9bafe0c0"
    },
    firebaseAuthKey: 'BLLnPLbDmSDKpvn13gfPMbM_uDGhiVI0rgb68nXDsNxScGjAXfOHVRPK1YSNsNRRhlplAu26iAnU16Kvp5bdFo0',
    dateSendGridStatistics: '2021-03-26',
    'outlook-redirect-uri': 'https://dws-staging.divinea.com',
    divinea: 'divinea-staging.web.app',
    googleAnalyticsId: 'G-13JWHSY38X',
    wineplataformOrders:  'https://api-crm-staging.divinea.com/api/wineplatform/webhook',
    wineplataformCustomers:  'https://api-crm-staging.divinea.com/api/wineplatform/contact-webhook ',
    wineplataformSubscription:  'https://api-crm-staging.divinea.com/api/wineplatform/privacy-contact-webhook',
    version_postfix: 'staging',
    version_ribbon: 'staging',
    winelens_url: 'https://staging.d193p3h5u2b1ar.amplifyapp.com',
  },
  hosts: {
    crm: {
      ...common.hosts.crm,
      host: 'api-crm-staging.divinea.com',
      protocol: 'https',
      port: ''
    },
    v2: {
      ...common.hosts.v2,
      host: 'api-staging.divinea.com',
      protocol: 'https',
      port: ''
    },
    v1: {
      ...common.hosts.v1,
      host: 'api-staging.divinea.com',
      protocol: 'https',
      port: ''
    },
    divinea: {
      site: 'divinea-staging.web.app'
    },
    websocket: {
      ...common.hosts.websocket,
      host: 'event-bus-staging.divinea.com',
      protocol: 'wss',
      port: '',
      root: 'ws'
    },
    divineaCom: {
      ...common.hosts.divineaCom,
      host: 'divinea.com',
      protocol: 'https',
      port: ''
    }
  }
};