import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { resource } from "@util/resource";
import { DWS } from "@interfaces/dws";
import { WineryInfo } from "../../interfaces/dws/winery-info";
import { retry } from "rxjs/operators";
import { WineryWineries } from "@interfaces/dws/winery-wineries";

@Injectable({
  providedIn: "root",
})
export class WineryInfoService {
  constructor() {}

  // INFO WINERY'S
  getWinery(wineryId: string): Observable<WineryInfo> {
    return resource("crm://winery/wineryId").params({ wineryId }).get();
  }

  updateWinery(wineryId: string, winery: DWS.WineryInfo.WineryDetailCreated): Observable<any> {
    return resource("crm://winery/wineryId/infowinery").params({ wineryId }).put<any>(winery);
  }

  // SERVICES
  getServices(wineryId: string): Observable<WineryInfo> {
    return resource("crm://winery/wineryId/service").params({ wineryId }).get();
  }

  addServices(ids: string[], wineryId: string): Observable<any> {
    return resource("crm://winery/wineryId/service").params({ wineryId }).post({ servicesIds: ids });
  }

  deleteService(serviceId: string, wineryId: string): Observable<any> {
    return resource("crm://winery/wineryId/service/id").params({ wineryId, id: serviceId }).delete();
  }

  // ATTACHMENTS
  createAttachment(type: "termsOfUse" | "privacyStatement", wineryId: string, file: File) {
    const fd = new FormData();
    fd.append("file", file);
    return resource("crm://winery/wineryId/attachment/type").params({ type, wineryId }).post(fd);
  }

  deleteAttachment(type: "termsOfUse" | "privacyStatement", wineryId: string): Observable<any> {
    return resource("crm://winery/wineryId/attachment/type").params({ type, wineryId }).delete();
  }

  // WINERY CHANNELS
  createSalesChannel(wineryId: string, sc: WineryInfo.WineryChannelCreate): Observable<any> {
    return resource("crm://winery/wineryId/wineryChannel").params({ wineryId }).post(sc);
  }

  updateSalesChannel(salesChannelId: string, wineryId: string, sc: WineryInfo.WineryChannelCreate): Observable<any> {
    return resource("crm://winery/wineryId/wineryChannel/id").params({ wineryId: wineryId, id: salesChannelId }).put(sc);
  }

  deleteSalesChannel(salesChannelId: string, wineryId: string): Observable<any> {
    return resource("crm://winery/wineryId/wineryChannel/id").params({ wineryId, id: salesChannelId }).delete();
  }

  // CLOSING DAYS
  saveClosingDays(wineryId: string, cp: WineryInfo.ClosingPeriod): Observable<any> {
    return resource("crm://winery/wineryId/closingdays").params({ wineryId }).post(cp);
  }

  removeClosingDays(wineryId: string, cp: WineryInfo.ClosingPeriod) {
    return resource("crm://winery/wineryId/closingdays/id").params({ wineryId, id: cp.id }).delete();
  }

  // RECEIPT EMAILS
  saveReceiptEmail(wineryId: string, email: string): Observable<any> {
    return resource("crm://winery/wineryId/notification-email/add").params({ wineryId }).post(email);
  }

  removeReceiptEmail(wineryId: string, email: string): Observable<any> {
    return resource("crm://winery/wineryId/notification-email/remove").params({ wineryId }).post({ email: email });
  }

  // ACCEPT PAYMENTS
  updateWineryPayment(wineryId: string, payments: DWS.WineryInfo.PaymentMethodUpdate): Observable<any> {
    return resource("crm://winery/wineryId/payment-method").params({ wineryId }).put<any>(payments);
  }

  // PAYMENTS
  addPaypalCredentials(ob: { data: { paypal_client_id: any; paypal_client_secret: any } }): Observable<any> {
    return resource("v2://host/connect-paypal").post(ob);
  }

  addNexiCredentials(ob: { data: { nexi_merchant_id: any; nexi_merchant: any; nexi_chiavemac: any } }): Observable<any> {
    return resource("v2://host/connect-nexi").post(ob);
  }

  removePayment(type: "paypal" | "stripe" | "nexi" | "pay_at_winery"): Observable<any> {
    return resource("v2://host/disconnect-payment").post({ type });
  }

  // WINERY WINERIES
  getWineryWineries(wineryId: string): Observable<WineryWineries> {
    return resource("crm://winery/wineryId/wineries").params({ wineryId }).get<WineryWineries>();
  }

  // SELFORDER LINKS
  getSelfOrderLinks(wineryId: string): Observable<DWS.Page<WineryInfo.SelfOrderLink>> {
    return resource("crm://preorder/links").params({ wineryId }).get<DWS.Page<WineryInfo.SelfOrderLink>>();
  }

  upsertSelfOrderLink(selfOrderLinks: WineryInfo.SelfOrderLink[]): Observable<WineryInfo.SelfOrderLink> {
    return resource("crm://preorder/links").post(selfOrderLinks);
  }

  removeSelfOrderLink(selfOrderLinkId: string): Observable<string> {
    return resource("crm://preorder/links").params({ selfOrderLinkId }).delete();
  }
}
