import * as moment from 'moment';
import {Parser} from 'json2csv';
import {formatISO} from 'date-fns'
import {save} from '@util/save-as';
import {saveXLSX} from '@util/save-xlsx';
import { VwReservation } from '../reservation';
import { tr } from '@util/tr';
import { locale } from '@util/locale';
import { DWSFormOptions } from '../experience-dws';
import { ExperiencePriceLabel } from '@interfaces/experience-price-label';

export namespace ReservationExporting {

  export interface Data {
    id: string;
    wineryId: string;
    titleEn: string;
    titleIt: string;
    reservationDate: string;
    experienceDate: string;
    birthDate: string;
    time: string;
    duration: string;
    languageEn: string;
    languageIt: string;
    guestcount01: number;
    guestcount02: number;
    experiencePriceExtras: {quantity: number}[];
    experiencePriceLabels: {quantity: number}[];
    grossTotalCents: number;
    discountCode: string;
    discountCents: number;
    netTotalCents: number;
    giftCoupon: string;
    origin: string;
    paid: string;
    paymentMethodEn: string;
    paymentMethodIt: string;
    roomName: string;
    employeeName: string;
    state: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    message: string;
    notes: string;
    country: string;
  }

  export const csvFields = (
    nLabels: number,
    nExtras: number,
    nParticipants: number,
    manualOrigins: any,
    paymentMethods: {[key: string]: any},
    rooms: {[key: string]: any},
    employees: {[key: string]: any}
  ) => [
    {value: 'id', label: 'ID prenotazione'},
    {value: (r: any) => r.experienceTranslations?.find((e: any) => e.translation_id == 'TITLE')?._translations[locale().locale], label: 'Esperienza'},
    {value: (r: any) => r.createdAt ? moment(r.createdAt, 'YYYY-MM-DD').utc(true).format('DD/MM/YYYY') : undefined, label: 'Data prenotazione'},
    {value: (r: any) => r.date ? moment(r.date, 'YYYY-MM-DD').utc(true).format('DD/MM/YYYY') : undefined, label: 'Data esperienza'},
    {value: (r: any) => moment(r.time, 'HH:mm:ss').format('HH:mm'), label: 'Orario'},
    {value: (r: any) => (r.experience || [{}])[0].duration || '', label: 'Durata'},
    {value: (r: any) => r.languageIso ? tr('lang_' + r.languageIso) : '', label: 'Lingua'},
    {value: 'guestCount01', label: 'Label 1'},
    ...Array.from(Array(nLabels - 1).keys()).map(i => ({value: (r: any) => r.reservationPriceLabels?.find((l: any) => l.position == (i+1))?.quantity || 0, label: `Label ${i + 2}`})),
    ...Array.from(Array(nExtras).keys()).map(i => ({value: (r: any) => r.reservationPriceExtras?.find((l: any) => l.position == i)?.quantity || 0, label: `Extra ${i + 1}`})),
    {value: (r: any) => r.otherData?.paymentMethodId ? paymentMethods[r.otherData.paymentMethodId] || '' : paymentMethods[r.optionalData?.paymentMethodId] || '', label: 'Modalità di pagamento'},
    {value: (r: any) => r.otherData?.payment2MethodId ? paymentMethods[r.otherData.payment2MethodId] || '' : paymentMethods[r.optionalData?.payment2MethodId] || '', label: 'Metodo di pagamento'},
    {value: (r: any) => centsInCurrency(computeSubtotal(r)), label: 'Subtotale'},
    {value: (r: any) => r.discountTotalCents ? `${r.otherData?.coupon || r.otherData?.couponCode || r.optionalData?.coupon || r.optionalData?.couponCode || ''} - ${centsInCurrency(r.discountTotalCents)}€` : '', label: 'Codice sconto'},
    {value: (r: any) => r.giftId ? centsInCurrency(r.giftAmountCents) : '', label: 'Gift'},
    {value: (r: any) => centsInCurrency(r.otherData?.manualDiscountTotalCents || r.optionalData?.manualDiscountTotalCents || r.manualDiscountTotalCents || 0), label: 'Sconto addizionale'},
    {value: (r: any) => centsInCurrency(r.netTotalCents || 0), label: 'Totale'},
    {value: (r: any) => r.paid ? tr('Yes') : tr('No'), label: 'Pagato'},
    {value: (r: any) => centsInCurrency(r.otherData?.paidTotalCents || r.optionalData?.paidTotalCents || (r.paid ? r.netTotalCents : 0) || 0), label: 'Importo pagato'},
    {value: (r: any) => centsInCurrency(r.dueTotalCents || 0), label: 'Importo da pagare'},
    {value: (r: any) => r.origin || manualOrigins.find((o: any) => o.id == r.manualOriginId)?.nameIt || 'Prenotazione Manuale', label: 'Origine'},
    {value: (r: any) => r.roomId ? rooms[r.roomId] || '' : '', label: 'Sala'},
    {value: (r: any) => r.employeeId ? employees[r.employeeId] || '' : '', label: 'Addetto'},
    {value: 'message', label: 'Messaggio cliente'},
    {value: (r: any) => r.state ? tr(r.state[0]?.toUpperCase() + r.state.substring(1)) : '', label: 'Stato'},
    {value: (r: any) => (r.otherData && "invoiceData" in r.otherData && r.otherData.invoiceData && r.otherData.invoiceData !== "{}") ? tr('Yes') : tr('No'), label: 'Richiesta Fattura'},
    {value: (r: any) => (r.otherData?.isSales || r.optionalData?.isSales) ? tr('Yes') : tr('No'), label: 'Fattura/Ricevuta'},
    {value: (r: any) => ((r.otherData?.checkedIn && r.otherData?.checkedIn == "true") || r.optionalData?.checkedIn) ? tr('Yes') : tr('No'), label: 'Check in'},
    {value: (r: any) => r.hasPaymentLink ? tr('Yes') : tr('No'), label: 'Link di pagamento'},
    {value: 'notes', label: 'Note interne'},
    {value: (r: any) => r.reservationContacts?.find((c: any) => c.contact_type == 'MASTER').first_name, label: 'Nome cliente'},
    {value: (r: any) => r.reservationContacts?.find((c: any) => c.contact_type == 'MASTER').last_name, label: 'Cognome cliente'},
    {value: (r: any) => r.reservationContacts?.find((c: any) => c.contact_type == 'MASTER').email, label: 'Mail cliente'},
    {value: (r: any) => r.reservationContacts?.find((c: any) => c.contact_type == 'MASTER').phone_number, label: 'Numero telefono cliente'},
    {value: (r: any) => r.reservationContacts?.find((c: any) => c.contact_type == 'MASTER')?.country_iso, label: 'Paese di residenza cliente'},
    {value: (r: any) => r.reservationContacts?.find((c: any) => c.contact_type == 'MASTER')?.other_data?.birthDate ? moment(r.reservationContacts?.find((c: any) => c.contact_type == 'MASTER')?.other_data?.birthDate, 'YYYY-MM-DD').utc(true).format('DD/MM/YYYY') : undefined, label: 'Data di nascita cliente'},
    //TODO ws-1200 here
    {value: (r: any) => r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData).companyName : '', label: 'Ragione Sociale'},
    {value: (r: any) => r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData).vat : '', label: 'P.IVA'},
    {value: (r: any) => r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData).sdi : '', label: 'Codice Univoco (SDI)'},
    {value: (r: any) => r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData).fiscalCode : '', label: 'Codice Fiscale'},
    {value: (r: any) => r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData).billingCountry?.name : '', label: 'Paese'},
    {value: (r: any) => r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData).billingAddress : '', label: 'Indirizzo'},
    {value: (r: any) => r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData).billingCity : '', label: 'Città'},
    {value: (r: any) => r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData).billingProvince : '', label: 'Provincia'},
    {value: (r: any) => r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData).billingZipCode : '', label: 'CAP'},
    {value: (r: any) => r.isTourOperator ? tr('Yes') : tr('No'), label: 'Tour Operator'},
    {value: (r: any) => r.reservationContacts?.filter((c: any) => c.contact_type != 'MASTER')?.length || 0, label: 'Contatti Aggiuntivi'},
    ...Array.from(Array(nParticipants).keys()).map(i => ({value: (r: any) => r.reservationContacts?.filter((c: any) => c.contact_type != 'MASTER')[i]?.email || '', label: `Partecipante ${i + 1}`}))
  ];

  export const saveAsXLSX = (r: VwReservation[], formOptions: DWSFormOptions) => {
    let maxLabel = r.reduce((max, res) => Math.max(max, res.reservationPriceLabels?.reduce((max, p) => Math.max(max, p.position + 1), 0) || 0), 1);
    let maxExtra = r.reduce((max, res) => Math.max(max, res.reservationPriceExtras?.reduce((max, p) => Math.max(max, p.position + 1), 0) || 0), 0);
    let maxParticipants = r.reduce((max, res) => Math.max(max, res.reservationContacts?.length || 0), 0) - 1;

    const xlsxData = r.map(d => mapToExcelData(d, maxLabel, maxExtra, maxParticipants, formOptions));
    saveXLSX(xlsxData, `res-${formatISO(new Date, {representation: 'date'})}.xlsx`)
  }

  export const mapToExcelData = (r: VwReservation, labelCount: number, extraCount: number, participantsCount: number, formOptions: DWSFormOptions) => {
    let labels: {[key: string]: any} = {'Label 1': r.guestCount01};
    let extras: {[key: string]: any} = {};
    let participantsEmails: {[key: string]: any} = {};
    let invoiceData = r.otherData?.invoiceData ? JSON.parse(r.otherData.invoiceData) : {};
    const labelsWithout1 = r.reservationPriceLabels?.filter(l => !l.islabel1) || [];
    for (let i = 1; i < labelCount; i++) {
      let key = 'Label ' + (i + 1);
      let value = labelsWithout1.find(l => l.position == i)?.quantity || 0;
      labels[key] = value;
    }

    for (let i = 0; i < extraCount; i++) {
      let key = 'Extra ' + (i + 1);
      let value = r.reservationPriceExtras?.find(l => l.position == i)?.quantity || 0;
      extras[key] = value;
    }

    for (let i = 0; i < participantsCount; i++) {
      let key = `Partecipante ${i + 1}`;
      let contact = r.reservationContacts?.filter(c => c.contact_type != 'MASTER')[i] || {};
      participantsEmails[key] = contact.email || '';
    }

    let countries: {[key: string]: any} = {},
    rooms: {[key: string]: any} = {},
    employees: {[key: string]: any} = {},
    paymentMethods: {[key: string]: any} = {};

    formOptions.countries.forEach(c => countries[c.iso!] = c.nameIt);
    formOptions.rooms.forEach(r => rooms[r.id!] = r.name);
    formOptions.employees.forEach(e => employees[e.id!] = e.firstName + ' ' + e.lastName);
    formOptions.paymentMethods.forEach(p => {
      paymentMethods[p.id!] = p.nameTranslations[locale().locale] || p.nameTranslations['it'];
      p.children?.forEach((p2: any) => {
        paymentMethods[p2.id!] = p2.nameTranslations[locale().locale] || p2.nameTranslations['it'];
      });
    });

    let contact = r.reservationContacts?.find(c => c.contact_type == 'MASTER') || {};

    return {
      'ID prenotazione': r.id,
      'Esperienza': r.experienceTranslations?.find(e => e.translation_id == 'TITLE')?._translations[locale().locale] || '',
      'Data prenotazione': r.createdAt ? moment(r.createdAt, 'YYYY-MM-DD').utc(true).format('DD/MM/YYYY') : undefined,
      'Data esperienza': r.date ? moment(r.date, 'YYYY-MM-DD').utc(true).format('DD/MM/YYYY') : undefined,
      'Orario': moment(r.time, 'HH:mm:ss').format('HH:mm'),
      'Durata': (r.experience || [{}])[0].duration || '',
      'Lingua': r.languageIso ? tr('lang_' + r.languageIso) : '',
      ...labels,
      ...extras,
      'Modalità di pagamento': r.optionalData?.paymentMethodId ? paymentMethods[r.optionalData.paymentMethodId] || '' : paymentMethods[r.otherData?.paymentMethodId] || '',
      'Metodo di pagamento': r.optionalData?.payment2MethodId ? paymentMethods[r.optionalData.payment2MethodId] || '' : paymentMethods[r.otherData?.payment2MethodId] || '',
      'Subtotale': centsInCurrency(computeSubtotal(r)),
      'Codice sconto': r.discountTotalCents ? `${r.otherData?.coupon || r.otherData?.couponCode || r.optionalData?.coupon || r.optionalData?.couponCode || ''} - ${centsInCurrency(r.discountTotalCents)}€` : '',
      'Gift': r.giftId ? centsInCurrency(r.giftAmountCents) : '',
      'Sconto addizionale': centsInCurrency(r.otherData?.manualDiscountTotalCents || r.optionalData?.manualDiscountTotalCents || r.manualDiscountTotalCents || 0),
      'Totale': centsInCurrency(r.netTotalCents || 0),
      'Pagato': r.paid ? tr('Yes') : tr('No'),
      'Importo pagato': centsInCurrency(r.otherData?.paidTotalCents || r.optionalData?.paidTotalCents || (r.paid ? r.netTotalCents : 0) || 0),
      'Importo da pagare': centsInCurrency(r.dueTotalCents || 0),
      'Origine': r.origin || (formOptions.manualOrigins.find(o => o.id == r.manualOriginId) as any)?.nameIt || 'Prenotazione Manuale',
      'Sala': r.roomId ? rooms[r.roomId] || '' : '',
      'Addetto': r.employeeId ? employees[r.employeeId] || '' : '',
      'Messaggio cliente': r.message,
      'Stato': r.state ? tr(r.state[0]?.toUpperCase() + r.state.substring(1)) : '',
      'Richiesta Fattura':  (r.otherData && "invoiceData" in r.otherData && r.otherData.invoiceData && r.otherData.invoiceData !== "{}") ? tr('Yes') : tr('No'),
      'Fattura/Ricevuta': (r.otherData?.isSales || r.optionalData?.isSales) ? tr('Yes') : tr('No'),
      'Check in': ((r.otherData?.checkedIn && r.otherData?.checkedIn == "true") || r.optionalData?.checkedIn) ? tr('Yes') : tr('No'),
      'Link di pagamento': r.hasPaymentLink ? tr('Yes') : tr('No'),
      'Note interne': r.notes,
      'Nome cliente': contact.first_name,
      'Cognome cliente':  contact.last_name,
      'Mail cliente': contact.email,
      'Numero telefono cliente': contact.phone_number,
      'Paese di residenza cliente': contact.country_iso ? countries[contact.country_iso] || '' : '',
      'Data di nascita cliente': contact.other_data?.birthDate ? moment(contact.other_data?.birthDate, 'YYYY-MM-DD').utc(true).format('DD/MM/YYYY') : undefined,
      'Ragione Sociale': invoiceData.companyName || '',
      'P.IVA': invoiceData.vat || '',
      'Codice Univoco (SDI)': invoiceData.sdi || '',
      'Codice Fiscale': invoiceData.fiscalCode || '',
      'Paese': invoiceData.billingCountry?.name || '',
      'Indirizzo': invoiceData.billingAddress || '',
      'Città': invoiceData.billingCity || '',
      'Provincia': invoiceData.billingProvince || '',
      'CAP': invoiceData.billingZipCode || '',
      'Tour Operator': r.isTourOperator ? tr('Yes') : tr('No'),
      'Contatti Aggiuntivi': r.reservationContacts?.filter(c => c.contact_type != 'MASTER')?.length || 0,
      ...participantsEmails
    }
  }

  export const saveAsCSV = (data: VwReservation[], formOptions: DWSFormOptions) => {
    let maxLabel = data.reduce((max, res) => Math.max(max, res.reservationPriceLabels?.reduce((max, p) => Math.max(max, p.position + 1), 0) || 0), 1);
    let maxExtra = data.reduce((max, res) => Math.max(max, res.reservationPriceExtras?.reduce((max, p) => Math.max(max, p.position + 1), 0) || 0), 0);
    let maxParticipants = data.reduce((max, res) => Math.max(max, res.reservationContacts?.length || 0), 0) - 1;

    let countries: {[key: string]: any} = {},
    rooms: {[key: string]: any} = {},
    employees: {[key: string]: any} = {},
    paymentMethods: {[key: string]: any} = {};

    formOptions.countries.forEach(c => countries[c.iso!] = c.nameIt);
    formOptions.rooms.forEach(r => rooms[r.id!] = r.name);
    formOptions.employees.forEach(e => employees[e.id!] = e.firstName + ' ' + e.lastName);
    formOptions.paymentMethods.forEach(p => {
      paymentMethods[p.id!] = p.nameTranslations[locale().locale] || p.nameTranslations['it'];
      p.children?.forEach((p2: any) => {
        paymentMethods[p2.id!] = p2.nameTranslations[locale().locale] || p2.nameTranslations['it'];
      });
    });

    const fields = ReservationExporting.csvFields(
      maxLabel,
      maxExtra,
      maxParticipants,
      formOptions.manualOrigins,
      paymentMethods,
      rooms,
      employees
    );
    const parser = new Parser({delimiter: ',', fields});
    const csvRows = parser.parse(data);
    save('\uFEFF' + csvRows).as(`res-${formatISO(new Date, {representation: 'date'})}.csv`, 'text/csv; charset=utf-8 ');
  }
}


const centsInCurrency = (cents: any = 0) => {
  return Number(cents) / 100;
}

const computeSubtotal = (r: VwReservation) => {
  return (
    r.reservationPriceLabels?.reduce((sum: number, l: ExperiencePriceLabel) =>
      sum + (l.quantity * (l.price_cents || r.experiencePrices?.find(p => p.position == l.position && p.price_type == 'LABEL')?.price_cents || 0))
    , 0) || 0
  ) + (
    r.reservationPriceExtras?.reduce((sum: number, e: any) =>
      sum + (e.quantity * (e.price_cents || r.experiencePrices?.find(p => p.position == e.position && p.price_type == 'EXTRA')?.price_cents || 0))
    , 0) || 0
  ) + ( r.reservationPriceLabels?.find(l => l.islabel1)
    ? 0
    : r.experiencePrices?.find(p => p.position == 0 && p.price_type == 'LABEL')?.price_cents || 0
  );
}


export type ReservationExporting = ReservationExporting.Data;

