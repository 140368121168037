<div mat-dialog-content>
    <mat-toolbar>
        <div>{{ title }}</div>
        <div class="actions">
            <button (click)="closeClicked()" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <div class="body-wrapper flex column align-center">
        <p class="center gray">{{ 'Show this QR Code' | translate }}</p>
        
        <canvas #qrCode></canvas>

        <p class="center gray" *ngIf="!!phone || !!email">{{ 'Or share the link' | translate }}</p>
        
        <div style="margin-top: 12px;" *ngIf="!!phone || !!email">
            <button
                (click)="sendWhatsApp()"
                class="whButton"
                mat-flat-button
                *ngIf="!!phone"
            >
                <img
                    alt="WhatsApp_logo"
                    class="whatsappLogo"
                    src="/assets/images/WhatsApp_logo.png"
                />
                <span class="buttonText">{{ "WhatsApp" | translate }}</span>
            </button>
            &nbsp;
            <button
                (click)="sendMailTo()"
                class="emailToConButton"
                mat-flat-button
                *ngIf="!!email"
            >
                <mat-icon>email</mat-icon>
                <span class="buttonText">{{ "Email" | translate }}</span>
            </button>
        </div>
    </div>
</div>