import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WSPaymentsService } from "@services/dws";
import { tr } from "@util/tr";
import * as QRCode from 'qrcode';

@Component({
  selector: 'web-qrcode-or-send-link',
  templateUrl: './qrcode-or-send-link.component.html',
  styleUrls: ['./qrcode-or-send-link.component.scss']
})
export class QrcodeOrSendLinkDialogComponent implements AfterViewInit {
  title: string;
  url: string;
  subject: string;
  message: string;
  email?: string;
  phone?: string;

  sendMenuOpen = false;

  @ViewChild('qrCode') qrCodeDiv!: ElementRef<HTMLCanvasElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<QrcodeOrSendLinkDialogComponent>,
  ) {
    this.title = data.title;
    this.url = data.url;
    this.email = data.email;
    this.phone = data.phone;
    this.subject = data.subject ?? tr("Here is the link for your wine tasting experience!");
    this.message = data.message ?? tr("Here is the link for your wine tasting experience!\nWhen you are on site, kindly open this link:\n{1}");
  }

  closeClicked() {
    this.dlg.close();
  }

  ngAfterViewInit() {
    this.generateQRCode();
  }

  generateQRCode() {
    QRCode.toCanvas(this.qrCodeDiv.nativeElement, this.url, {width: 300})
  }

  sendWhatsApp() {
    var message = this.generateMessage();
    var phone = this.phone
      ?.replace("+", "")
      .replace(" ", "");
    const url = "https://wa.me/" + phone + "/?text=" + encodeURIComponent(message);
    window.open(url, "_blank");
  }

  sendMailTo() {
    var message = this.generateMessage();
    var subject = this.generateSubject();
    var email = this.email;
    const url =
      "mailto:" +
      email +
      "?subject=" +
      encodeURIComponent(subject) +
      "&body=" +
      encodeURIComponent(message);
    window.open(url, "_blank");
  }

  generateMessage(): string {
    return this.formatString(this.message, this.url);
  }

  generateSubject(): string {
    return this.formatString(this.subject, this.url);
  }

  formatString(template: string, ...values: any[]): string {
    return template.replace(/{(\d+)}/g, (match, index) => {
      const adjustedIndex = parseInt(index) - 1;
      const value = values[adjustedIndex];
      return value !== undefined ? value : match;
    });
  }
}