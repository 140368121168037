<div style="bottom: 8000px; position: fixed; font-family: 'Poppins' !important">
  <div
    *ngIf="exportingPdf"
    id="pdfExportExperiences"
    class="contentFirstWrapper"
  >
    <div class="contentDivWrapper">
      <div class="contentDivTitleRes">
        <div
          class="summaryExperienceTitle"
          style="
            display: flex;
            column-gap: 1.5rem;
            padding: 0 0 0.5cm 0;
            flex-wrap: wrap;
          "
        >
          <div>
            {{ "Summary of reservations" | translate }}
          </div>
          <div>
            {{ getStartAndEndDate() }}
          </div>
          <div>
            {{ getStartAndEndTime() }}
          </div>
          <div>
            {{ company.name }}
          </div>
        </div>
        <div class="new-logo">
          <img
            alt=""
            src="assets/images/logo-blue-horizontal.svg"
            style="
              width: 150px;
              position: relative;
              display: block;
              float: left;
              margin: 5px;
            "
          />
        </div>
      </div>

      <div
        *ngFor="let sect of this.dataPdf!.results; let j = index"
        style="padding: 0.2cm 0; page-break-inside: avoid"
      >
        <div class="newSummaryExperienceNameLine">
          <div class="summary-fixed-details">
            <div class="newSingleReservationHeaderElement" style="width: 5cm">
              {{ sect.summary.experienceName }}
            </div>
            <div class="newSingleReservationHeaderElement">
              {{ sect.summary.date | date : "dd/MM/yy" }}
            </div>
            <div class="newSingleReservationHeaderElement">
              {{ formatTime(sect.summary.time) }}
            </div>
            <div class="newSingleReservationHeaderElement">
              {{ "lang_" + sect.summary.language | translate }}
            </div>
            <div class="newSingleReservationHeaderElement">
              {{ "Reservations" | translate }}:
              {{ sect.summary.totalParticipants }}
            </div>
          </div>

          <div class="summary-labels-and-extras">
            <div
              *ngFor="
                let l1 of labelsAndExtrasJoinSummaryToObjArr(sect);
                let aa = index
              "
            >
              <div class="newSingleReservationHeaderElement">
                {{ l1.labelName }}: {{ l1.labelValue }}
              </div>
            </div>
          </div>
        </div>

        <div class="newReservationHeaderLine">
          <div class="left-side-elements" style="width: 15.6cm">
            <div
              class="client-element"
              style="width: 3.2cm; align-items: flex-start"
            >
              <div class="newReservationHeaderLineElement">
                <div class="newReservationHeaderLineTitle">
                  {{ "Client" | translate }}
                </div>
                <div
                  class="newReservationHeaderLineValues"
                  *ngFor="let r of sect.reservations; let i = index"
                >
                  <div
                    class="newReservationHeaderLineValue truncate-text"
                    style="align-items: flex-start"
                  >
                    <div class="truncate-text">
                      {{ r.masterContactName }}
                    </div>
                    <div
                      style="
                        display: flex;
                        width: 100%;
                        flex-direction: row;
                        justify-content: space-between;
                      "
                    >
                      <div *ngIf="r.reservationContacts[0].phone_number">
                        {{ r.reservationContacts[0].phone_number.slice(0, 14) }}
                      </div>
                      <div>
                        {{ r.reservationContacts[0].country_iso }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="labels-and-extras">
              <div
                class="newReservationHeaderLineElement"
                *ngFor="let l1 of getLabels(sect).slice(0, 3)"
              >
                <div class="newReservationHeaderLineTitle">
                  {{ l1.labelName }}
                </div>
                <div class="newReservationHeaderLineValues">
                  <div
                    class="newReservationHeaderLineValue"
                    *ngFor="let r of sect.reservations"
                  >
                    {{ getLabelOrZero(r, l1.labelName) }}
                  </div>
                </div>
              </div>
              <div
                class="newReservationHeaderLineElement"
                *ngIf="getLabels(sect).length > 3"
              >
                <div class="newReservationHeaderLineTitle">
                  {{ "Other Labels" | translate }}
                </div>
                <div class="newReservationHeaderLineValues">
                  <div
                    class="newReservationHeaderLineValue"
                    *ngFor="let r of sect.reservations"
                  >
                    {{ getOtherLabelsValue(r, sect) }}
                  </div>
                </div>
              </div>
              <div
                class="newReservationHeaderLineElement"
                *ngFor="let l1 of getExtras(sect).slice(0, 3)"
              >
                <div class="newReservationHeaderLineTitle">
                  {{ l1.labelName }}
                </div>
                <div class="newReservationHeaderLineValues">
                  <div
                    class="newReservationHeaderLineValue"
                    *ngFor="let r of sect.reservations; let i = index"
                  >
                    {{ getExtraOrZero(r, l1.labelName) }}
                  </div>
                </div>
              </div>
              <div
                class="newReservationHeaderLineElement"
                *ngIf="getExtras(sect).length > 3"
              >
                <div class="newReservationHeaderLineTitle">
                  {{ "Other Extras" | translate }}
                </div>
                <div class="newReservationHeaderLineValues">
                  <div
                    class="newReservationHeaderLineValue"
                    *ngFor="let r of sect.reservations"
                  >
                    {{ getOtherExtrasValue(r, sect) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-side-elements" style="width: 12.9cm">
            <div
              class="newReservationHeaderLineElement"
              style="width: 1.7cm; font-weight: 600"
            >
              <div class="newReservationHeaderLineTitle">
                <!--Totale-->
                {{ "Total" | translate }}
              </div>
              <div class="newReservationHeaderLineValues">
                <div
                  class="newReservationHeaderLineValue"
                  *ngFor="let r of sect.reservations; let i = index"
                >
                  <!--Totale-->
                  {{ r.netTotalCents / 100 }} €
                </div>
              </div>
            </div>

            <div
              class="newReservationHeaderLineElement"
              style="width: 1.75cm; font-weight: 600"
            >
              <div class="newReservationHeaderLineTitle">
                {{ "Da pagare" | translate }}
                <!--Da pagare-->
              </div>
              <div class="newReservationHeaderLineValues">
                <div
                  class="newReservationHeaderLineValue"
                  *ngFor="let r of sect.reservations; let i = index"
                >
                  <ng-container
                    *ngIf="r.dueTotalCents > 0; else daRimborsare"
                    class="newReservationHeaderLineValue"
                  >
                    <div style="color: red; text-align: center">
                      <!--Da pagare-->
                      {{ r.dueTotalCents / 100 }} €
                    </div>
                  </ng-container>

                  <ng-template
                    #daRimborsare
                    class="newReservationHeaderLineValue"
                  >
                    <ng-container *ngIf="r.dueTotalCents < 0; else aPosto">
                      <div style="color: green; text-align: center">
                        {{ (r.dueTotalCents / 100) * -1 }} €
                        <p style="font-size: 0.4rem; margin: 0 !important">
                          (Da rimborsare)
                        </p>
                      </div>
                    </ng-container>
                  </ng-template>

                  <ng-template #aPosto class="newReservationHeaderLineValue">
                    <div>0 €</div>
                  </ng-template>
                </div>
              </div>
            </div>

            <div class="newReservationHeaderLineElement" style="width: 2cm">
              <div class="newReservationHeaderLineTitle">
                {{ "Sala" | translate }}
                <!--Sala-->
              </div>
              <div class="newReservationHeaderLineValues">
                <div
                  class="newReservationHeaderLineValue"
                  *ngFor="let r of sect.reservations; let i = index"
                >
                  <!--TODO changes with new DTO-->
                  <!--Sala-->
                  {{ roomName(r.roomId) }}
                </div>
              </div>
            </div>

            <div class="newReservationHeaderLineElement" style="width: 2.45cm">
              <div class="newReservationHeaderLineTitle">
                {{ "Addetto" | translate }}
                <!--Addetto-->
              </div>
              <div class="newReservationHeaderLineValues">
                <div
                  class="newReservationHeaderLineValue"
                  *ngFor="let r of sect.reservations; let i = index"
                >
                  <!--TODO changes with new DTO-->
                  <!--Addetto-->
                  {{ employeeName(r.employeeId) }}
                </div>
              </div>
            </div>

            <div class="newReservationHeaderLineElement" style="width: 5cm">
              <div class="newReservationHeaderLineTitle">
                {{ "Note" | translate }}
                <!--Nota-->
              </div>
              <div class="newReservationHeaderLineValues">
                <div
                  class="newReservationHeaderLineValue"
                  *ngFor="let r of sect.reservations; let i = index"
                  style="
                    display: inline;
                    font-size: 0.5rem !important;
                    align-items: flex-start;
                    -webkit-box-orient: vertical;
                    line-clamp: 6;
                    -webkit-line-clamp: 6;
                    overflow: hidden;
                    /* max-width: 4.5cm; */
                    line-height: 1.2;
                  "
                >
                  <!--TODO changes with new DTO-->
                  <!--Nota-->
                  {{ r.notes }}

                  <div
                    *ngIf="r.message"
                  >
                    - {{getMessage(r.message)}} -
                  </div>
                  <div
                    *ngIf="(r.otherData && r.otherData.invoiceData && r.otherData.invoiceData !== '{}')"
                    style="font-style: italic;">
                    {{"Invoice requested" | translate}}
                  </div>
                  <!--
                  This is a custom note to see how would a 100 character note
                  fit inside this layout box in here, gbye
                  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
