import { FormBuilder, Validators } from '@angular/forms';
import {Audit} from "./audit";
import {Winery} from "./winery";
import { Channel } from './channel';
import { Experience } from '@interfaces/experience';

export namespace DiscountCode {
  export enum DiscountTypeEnum {
    percent = 'percent',
    absolute = 'absolute'
  }

  export enum CouponEnabledPurchases {
    RESERVATION = "RESERVATION",
    ORDER = "ORDER",
    PREORDER = "PREORDER",
    GIFT = "GIFT"
  }  

  export interface Data extends Audit {
    id?: string;
    code?: string;
    discountPercent?: number;
    active?: boolean;
    discountType?: DiscountTypeEnum;
    purchaseType?: CouponEnabledPurchases;
    discountCents?: number;
    startAt?: Date | string;
    endAt?: Date | string;
    minimumOrderCents?: number;
    useMax?: number;
    useCount?: number;
    winery?: Winery.Data;
    couponRules?: CouponRule[];
  }

  interface CouponRule {
    id: string;
    entityType: string;
    entityId: string;
    rules: Record<string, any>; 
  }
  
  export function createFormGroup(fb: FormBuilder, discountCode?: Partial<Data>) {
    const f = fb.group({
      code: ['', Validators.required],
      discountPercent: [''],
      active: [''],
      discountType: [''],
      discountCents: [''],
      startAt: ['', Validators.required],
      endAt: ['', Validators.required],
      minimumOrderCents: [''],
      useMax: [''],
      useCount: [''],
      winery: [{
        id: ['', Validators.required]
      }]
    })
    if (discountCode)
      f.patchValue(discountCode);
    return f;
  }
}

export type DiscountCode = DiscountCode.Data;
