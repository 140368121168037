<div class="popUpComponentContainer animate__animated animate__fadeInUp">
  <div class="popUpIconButtonContainer" *ngIf="isButtonVisible">

    <button [matBadge]="notificationNumber" [matBadgeHidden]="notificationNumber <= 0" mat-fab color="accent"
      class="popUpIconButton" aria-label="Message Button" (click)="toogleMessage()">
      <mat-icon aria-hidden="false" *ngIf="!isMessageVisible">message</mat-icon>
      <mat-icon aria-hidden="false" *ngIf="isMessageVisible">close</mat-icon>
    </button>
  </div>

  <div class="popUpMessageContainer" *ngIf="isMessageVisible">
    <mat-icon class="popUpIconClose" (click)="dismissMessage()">
      close
    </mat-icon>
    <div class="popUpMessageContent">
      <!-- MESSAGE CONTENT-->


        <div *ngIf="message" class="referral-program">
            <h1>{{ message.title }}</h1>
            <p>{{ message.message }}</p>
        </div>

      <div *ngIf="!message" class="referral-program">
        <div [innerHTML]="cleanStaticMessageHTML()"></div>
      </div>

        <!-- REPLACE UP CODE FOR CHANGE MESSAGE-->
    </div>
  </div>

</div>